export const uz = {
  header: {
    address: "Toshkent sh. Diydor ko`chasi. 71",
  },
  menu: {
    about: "UMFT haqida",
    univer: "Universitet",
    campus: "O`quv binosi",
    structure: "Struktura",
    faculty: "Fakultetlar",
    bakalavr: "Bakalavriat",
    magistr: "Magistratura",
    admis: "Qabul komissiyasi",
    direction: "Yo`nalishlar",
    condition: "Qabul shartlari",
    admission: "Qabul kvotasi",
    relocation: "O`qishni ko`chirish",
    contract: "Shartnoma summasi",
    contact: "Aloqa",
    faq: "FAQ",
    about_f: "Universitet haqida",
    grant: "Grant",
    conf: "Konferensiya",
    journal: "Jurnal",
    gallery: "Galereya",
    promo: "Qabul aksiyasi",
    vaucher: "Qabul vaucheri",
  },
  double: `Qo'shma ta'lim dasturi`,
  double_title: "UMFT hamda Far East University universitetlari hamkorligi",
  grant: {
    discount: "Grant chegirma",
    direction: "Yo`nalish",
    title: "Grant tanlovining final bosqich natijalari",
    50: `50 foizlik grant sohiblari ro'yhati`,
    75: `75 foizlik grant sohiblari ro'yhati`,
    100: `100 foizlik grant sohiblari ro'yhati`,
    25: `Grantsiz 25 foizlik chegirmaga ega bo'lganlar ro'yhati`,
    name: "FISh",
    ball: "Ball",
  },
  certdownload: "Sertifikat fayli",
  vaucher: "Voucher raqami (agar mavjud bo'lsa)",
  nav: {
    admit: "Hujjat topshirish",
    relocation: "O`qishni ko`chirish",
  },
  about: {
    item_1: {
      title: "Ta`lim barchaga",
      text: "Qobiliyatli va iqtidorli yoshlarni aniqlash, qo`llab-quvvatlash va grantlar taqdim etish",
    },
    item_2: {
      title: "Gibrid ta`lim",
      text: "O`quv dasturi bilan birgalikda xalqaro sertifikatlashgan o`quv kurslarida masofadan o`qish",
    },
    item_3: {
      title: "Ilm-fan",
      text: "Hamkorlikda fan, ta`lim va ishlab chiqarishning samarali integratsiyasini ta`minlash",
    },
    item_4: {
      title: "Xalqaro standart",
      text: "Ta`limning xalqaro standartlari asosida zamonaviy bilimga ega yuqori malakali kadrlarni tayyorlash",
    },

    title: "Biz haqimizda",
    text: `<h2>O'quv binosi va asosiy qulayliklar</h2>
        <p>Universitetda taʼlim berish yetakchi professor-o‘qituvchilar tomonidan zamonaviy texnologiyalar bilan jihozlangan o‘quv xonalarida olib boriladi.</p>
        <p>Universitet ixtiyorida maʼruza va amaliy mashg‘ulotlar xonalari, kompyuter laboratoriyalari, axborot resurs markazi va zamonaviy kompyuterlar bilan jihozlangan elektron kutubxona mavjud.</p>
        <p>Universitet binosi to‘liq wi-fi internet tarmog‘i bilan qamrab olingan bo'lib, talabalarga erkin foydalanish imkoniyati beriladi</p>`,
  },
  welcome: {
    title: "Bizning universitet",
    slogan: "Kelajagingiz uchun eng maqbul hamkor",
    text: `Xalqaro tajribalardan kelib chiqib, oliy ta'limning ilg'or standartlari asosida mehnat bozori talablariga mos yuqori malakali mutaxassislarni tayyorlash`,
    more: "Batafsil",
  },
  welcome_2: {
    title: "Diqqat tanlov!!!",
    slogan: "180 ta grant o`rinlari",
    text: `University of Management and Future Technologies universiteti 2024/2025 o'quv yili uchun grant o'rinlariga abiturientlarning qabul tanlovini e'lon qiladi.`,
    more: "Tanlovda qatnashish",
    result: "Grant natijalari",
  },
  welcome_3: {
    title: "2024-2025 o'quv yili uchun qabul aksiyasi!!!",
    slogan: "Talaba bo'ling va qabul aksiyasida qatnashing",
    text: `UMFT universitetining omadli talabalari safida bo'ling!`,
    more: "Aksiya shartlari",
  },
  programlist: {
    title: "Universitet Fakultetlari",
    text: "Universitetda uchta fakultet mavjud bo‘lib ularda IT texnologiyalar muhandisligi, iqtisodiyot va biznes boshqaruvi hamda pedagogika va filologiya bo‘yicha bakalavr va magistr mutaxassislar tayyorlanadi",
  },
  mission: {
    title: "Oliy maqsad va vazifalarimiz",
    subtitle: "Bizning maqsad",
    text: `<p>Oliy taʼlim sohasida yuqori reyting ko‘rsatkichlariga ega bo‘lgan, xalqaro standartlar talablari darajasida sifatli taʼlim beradigan, raqobatbardosh, zamonaviy yetuk mutaxassislarni tayyorlovchi universitetlardan biri bo‘lish</p>`,
  },
  price: {
    title: "Universitet yo'nalishlari ro'yhati",
    subtitle: `Ta'lim yo'nalishlari va mutaxassisliklar`,
    currency: `mln. so'm / yil`,
  },
  partners: {
    tuit: "Toshkent Axborot Texnologiyalari Universiteti",
    pedagogika: "Toshkent Davlat Pedagogika Universiteti",
    moliya: "Toshkent Moliya Instituti",
    guldu: "Guliston davlat universiteti",
    nammti: "Namangan muhandislik-texnologiya instituti",
    digital: "Raqamli iqtisodiyot tadqiqotlar markazi",
    avloniy: "A. Avloniy nomidagi milliy-tadqiqot instituti",
    center: "Raqamli ta'lim texnologiyalarini rivojlantirish markazi",
    title: "Hamkorlar",
  },
  footer: {
    text: "Biz bilan ilmning yuqori cho‘qqilarini egallang va malakali mutaxassisga aylaning",
    lic: "",
  },
  modal: {
    title: "O`z savolingizni jo`nating",
    name: "O`zingizni tanishtiring",
    question: "Savolingiz matni",
    send: "Jo'natish",
    thnks:
      "Savolingiz muvaffaqiyatli jo'natildi. <br> Savolingiz ko'rib chiqilib, javob beramiz. Tashakkur savolingiz uchun.",
  },
  breadcrumb: {
    home: "Bosh sahifa",
  },
  contact: {
    title: "Aloqa",
    phone: "Telefon raqam",
    address: {
      title: "Manzil",
      value: "Toshkent shahar, Chilonzor tumani Diydor ko'chasi 71",
      value_2:
        "Toshkent shahar, Bog'ibo'ston ko'chasi, 3A uy (Rakat mahalla, Tekstil)",
    },
    email: "Elektron pochta",
    main_place: "Asosiy bino",
    second_place: "2-bino",
    second_text:
      "Universitet 2-binosiga kelishda yuqoridagi avtobuslarning <u>Tekstil kombinati</u> bekatida tushiladi",
  },
  bus: {
    title: "Avtobus bekatlari",
    station: "Bekat nomi",
    number: "Avtobus raqami",
    direction: "Harakat-oralig'i",
    time: "Vaqti",
    line_1: "Algoritm Zavodi bekati",
    mini: "Marshrutka bekatlari",
    mininumber: "Marshrutka raqami",
    metro: "Metro",
  },
  page: {
    faq: "Ko`p beriladigan savollar",
    question: "Savol jo'natish",
  },
  report: {
    title: "Talaba ma'lumotlari",
    name: "Talaba F.I.Sh.",
    lvl: "Ta'lim turi",
    studyType: "Ta'lim shakli",
    year: "O'qish muddati",
    course: "O'qish kursi",
    courseCur: "kurs",
    info: "Shartnoma ma'lumotlari",
    direction: "Ta'lim yo'nalishi",
    quantity: "Shartnoma raqami",
    date: "Shartnoma sanasi",
    summa: "Shartnoma summasi",
    copy: "Shartnoma nusxasi",
    download: "Yuklab olish",
    sum: "so'm",
  },

  admit: {
    dtm: "DTM ID raqami (mavjud bo'lsa)",
    studyType_off: "Kunduzgi",
    sale: "Chegirma sertifikatini yuklang (agar mavjud bo'lsa)",
    studyType_on: "Sirtqi",
    title: "Ariza yuborish",
    success_title: "Hurmatli abiturient!!!",
    success_text:
      "Sizning arizangiz UMFT qabul komissiyasiga muvaffaqiyatli yuborildi. Tez orada universitet qabul komissiyasi mas'ul xodimi arizangiz bo'yicha siz bilan bog'lanadi. Iltimos telefoningiz faol holatda bo'lsin",
    step_1: "Shaxsiy ma'lumotlar",
    step_2: "Ta'lim ma'lumotlari",
    step_3: "Ariza yuborish",
    lname: "Familiyangiz",
    name: "Ismingiz",
    secondname: "Sharifingiz (отчество)",
    gender: "Jinsi",
    male: "Erkak",
    female: "Ayol",
    phone: "Telefon raqami",
    email: "Email manzili",
    country: "Fuqarolik",
    selectCountry: "Mamlakatni tanlang",
    passport: "Pasport/ID karta raqami",
    bdate: "Tug'ilgan sanasi",
    province: "Viloyati",
    selectProvince: "Viloyatni tanlang",
    district: "Shahar/tuman",
    selectDistrict: "Tumanni tanlang",
    address: "Yashash manzili",
    rectype: "Qabul maqsadi",
    selectList: "Ro`yhatdan tanlang",
    studyName: "Qayerni tugatgan?",
    studyNameShow: "Tugatgan o'quv maskani",
    studyId: "Shahodatnoma/diplom seriyasi va raqami",
    studyShow: "Shahodatnoma/diplomi",
    foreign: "O'qigan xorijiy til",
    studyPart: "Qabul ta'lim turi",
    direction: "Tanlagan yo'nalishi",
    directionShow: "Yo'nalish",
    language: "Ta'lim tili",
    studyType: "Ta'lim shakli",
    studyDocument: "Shahodatnoma/diplomni faylini yuklang",
    studyDocumentShow: "Shahodatnoma/diplom fayllari",
    studyDocumentError: "Faylning hajmi 4 mb dan oshmasligi lozim",
    academ: "Akademik ma’lumotnoma/Transkript faylini yuklang",
    academError: "Akademik ma'lumotnoma fayl hajmi 4 mb dan oshmasligi lozim",
    academShow: "Akademik ma’lumotnoma/Transkript fayllari",
    pasImg: "Passport  yoki ID kartaning old va orqa qismini yuklang",
    pasImgError: "Passport faylning hajmi 4 mb dan oshmasligi lozim",
    pasShow: "Pasport fayllari",
    img: "Rasm faylini yuklang",
    imgShow: "Rasm fayllari",
    imgError: "Rasm faylning hajmi 4 mb dan oshmasligi lozim",
    imgType: "Rasm fayliga faqat img,png format fayllarini yuklash mumkin",
    aggree: "Ma'lumotlarning haqiqiyligi va to'g'riligini tasdiqlayman",
    prev: "Ortga",
    next: "Keyingi",
    code: "Kodni tasdiqlang",
    send: "Yuborish",
    save: "Saqlash",
    info: "raqamga yuborilgan kodni kiriting",
    ok: "Tasdiqlash",
    cur: "ta",
    exam: "Kirish imtihon sanasini tanlang",
    examtitle: "Kirish imtihoni",
    sert: "Til bilish sertifikatlari (agar mavjud bo`lsa)",
    sertShow: "Til bilish sertifikatlari",
    choose_direction: "Yo`nalishni tanlang",
    contract_salary: "Kontrakt narxi",
    private_info: "Shaxsiy ma`lumotlar",
    contact_info: "Bog`lanish ma`lumotlari",
    study_info: "O`qish ma`lumotlari",
    choose_studyType: `Qaysi shaklda o'qishni istaysiz?`,
    choose_language: `Qaysi tilda o'qishni istaysiz?`,
    choose_foreign: `Qaysi chet tilini o'qigansiz?`,
    choose_exam: `Kirish imtihon sanasini tanlang`,
  },
};
