<template>
  <div :class="`program__box ${classItem}`">
    <div :style="{
      backgroundImage: `url(${url}/${program.poster})`
    }"  class="program__img">
    </div>
    <div :class="`program__info ${program.study == 0 ? 'bakavlr' : 'magistr'}`">
        <div class="program__study">
          {{ program.study == 0 ? $t('menu.bakalavr') : $t('menu.magistr') }}
        </div>
        <div class="program__title">{{locale == 'uz' ? program.title : program.translate.find(t => t.language == locale).title ? program.translate.find(t => t.language == locale).title : program.title }}</div>
        <div class="program__desc" v-html="locale == 'uz' ? program.desc : program.translate.find(t => t.language == locale).desc ? program.translate.find(t => t.language == locale).desc : program.title"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props:['program','classItem'],
  computed:{
    ...mapGetters([
      'url',
      'locale'
    ]),
  },
  methods: {
    
  }
}
</script>

<style>

</style>