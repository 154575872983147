
const faq = {
    state: () => ({
        faqs:[]
    }),
    getters:{
        adminFaqs(state){
            return state.faqs.filter(faq => !faq.name)
        }
    },
    mutations:{
        faqs(state,payload){
            state.faqs = payload
        }
    },
    actions:{
        async allFaqs(context){
            
            let res = await context.dispatch('getAxios','faq/all')
            if (res.status === 200){

                context.commit('faqs',res.data)  
            } 
        },
        async addFaqs(context,payload){
            return context.dispatch('postAxios',{
                url:'faq/add',
                data: payload
            })
             
        },

    }
}

export default faq