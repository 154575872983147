import cookie from 'vue-cookies'
import i18n from '@/lang/message'
export const language = {
    state: () => ({
        locale:'uz',
        listLocale:['uz','ru','en'],
        titleLocale:{
            uz: 'O`z',
            ru: 'Ru',
            en: 'En'
        }
    }),
    getters:{
        titleLocale(state){
            return state.titleLocale
        },
        allLocale(state){
            return state.listLocale
        },
        listLocale(state){
            return state.listLocale.filter(local => local !== state.locale)
        },
        locale(state){
            return state.locale
        }
    },
    mutations: {
        setLocale(state,payload){
            state.locale = payload
            i18n.global.locale = payload
            cookie.set('umft-lang',payload)
        }
    }
}