<template>
  <div class="program">
    <div class="container">
      <h3 class="d-flex align-items-center justify-content-center">
        <span class="sq"></span>
        {{ $t("menu.faculty") }}
      </h3>
      <h2 class="text-center">{{ $t("programlist.title") }}</h2>
      <p class="text-center mb-40">{{ $t("programlist.text") }}</p>
      <el-row :gutter="30">
        <el-col :lg="8" :md="24" v-for="facul of facultys" :key="facul._id">
          <router-link
            :to="{ name: 'faculty', params: { slogan: facul.slogan } }"
            class="program__box"
          >
            <img :src="`${url}/${facul.poster}`" alt="" />
            <div class="black">
              <div class="bg">
                <div
                  class="title"
                  v-html="
                    locale == 'uz'
                      ? facul.title
                      : facul.translate.find((tr) => tr.language == locale)
                          ?.title
                  "
                />
                <div
                  class="desc"
                  v-html="
                    locale == 'uz'
                      ? facul.desc
                      : facul.translate.find((tr) => tr.language == locale)
                          ?.desc
                  "
                ></div>
              </div>
            </div>
          </router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ProgramItem from "./ProgramItem.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { mapGetters } from "vuex";
export default {
  components: { ProgramItem, Splide, SplideSlide },
  computed: {
    ...mapGetters(["facultys", "url", "locale"]),
  },
  methods: {
    addBr(title) {
      let arr = title.split(" ");
      return arr.length < 4
        ? `${arr.slice(0, 2).join(" ")} <br> ${arr.slice(2).join(" ")}`
        : title;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/components/home/program.scss";
</style>
