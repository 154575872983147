<template>
  <div class="pricelist">
    <div class="container">
      <TitleBox
        :title="$t('price.title')"
        titleclass="text-center"
        :subtitle="$t('price.subtitle')"
        classlist="justify-content-center"
      />
      <homecard
        :directions="directions.filter((dir) => dir.study == 0)"
        :title="$t('menu.bakalavr')"
        :photo="'photo2.png'"
      />
      <homecard
        :directions="directions.filter((dir) => dir.study == 1)"
        :title="$t('menu.magistr')"
        :photo="'photo.webp'"
        :color="'#0227269e'"
        :top="'280px'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import homecard from "../direction/homecard.vue";
import TitleBox from "../TitleBox.vue";

export default {
  data() {
    return {
      kind: true,
    };
  },
  components: { TitleBox, homecard },
  computed: {
    ...mapGetters(["directions"]),
  },
};
</script>

<style lang="scss">
@import "@/styles/components/home/pricelist.scss";
</style>
