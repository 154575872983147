import { createI18n } from 'vue-i18n'

import { uz } from "./uz";
import { en } from "./en";
import { ru } from "./ru";

const messages = {
    uz, en, ru
}


const i18n = createI18n({
    locale:'uz',
    messages  
})

export default i18n