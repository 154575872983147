import { createStore } from 'vuex'

import direction from './modules/direction'
import helpers from './helpers'
import faq from './modules/faq'
import settings from './modules/settings'
import page from './modules/page'
import { students } from './modules/student'
import { language } from './modules/language'
import faculty from './modules/faculty'
export default createStore({
  state: {
    url: "https://site.umft.uz",
    uri: "https://qabulapi.umft.uz",
    voucher_url: "https://voucherapi.umft.uz",
    navclass: "",
    month: [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ],
    headerBtn: true,
  },
  getters: {
    headerBtn({ headerBtn }) {
      return headerBtn;
    },
    month(state) {
      return state.month;
    },
    navclass(state) {
      return state.navclass;
    },
    url(state) {
      return state.url;
    },
    uri(state) {
      return state.uri;
    },
    voucher_url: ({ voucher_url }) => voucher_url,
  },
  mutations: {
    headerBtn(state, payload) {
      state.headerBtn = payload.val;
    },
    navclass(state, payload) {
      state.navclass = payload;
    },
  },
  modules: {
    direction,
    helpers,
    faq,
    settings,
    page,
    students,
    language,
    faculty,
  },
});
