<template>
  <div class="directions">
    <div class="pricelist__title">{{ title }}</div>
    <el-row :gutter="15">
      <el-col
        :lg="8"
        :md="12"
        v-for="direction of directions"
        :key="direction._id"
        class="mb-20"
      >
        <div :class="`direction ${direction.facul?.slogan}`">
          <div class="faculty">
            {{
              locale == "uz"
                ? direction.facul?.title
                : direction.facul?.translate.find(
                    (dir) => dir.language == locale
                  )?.title
            }}
          </div>
          <router-link
            :to="{
              name: 'direction',
              params: { slogan: direction.slogan },
            }"
            class="title"
          >
            {{
              locale == "uz"
                ? direction.title
                : direction.translate.find((dir) => dir.language == locale)
                    ?.title
            }}
          </router-link>
          <div
            class="desc"
            v-html="
              locale == 'uz'
                ? direction.desc
                : direction.translate.find((dir) => dir.language == locale)
                    ?.desc
            "
          />
          <div class="d-flex align-items-end justify-content-between mt-auto">
            <div>
              <div class="studytype">{{ $t("admit.studyType_off") }}</div>
              <div class="price">
                {{ direction.price }} <span>{{ $t("price.currency") }}</span>
              </div>
            </div>
            <div v-if="direction.onprice">
              <div class="studytype">{{ $t("admit.studyType_on") }}</div>
              <div class="price">
                {{ direction.onprice }} <span>{{ $t("price.currency") }}</span>
              </div>
            </div>
          </div>
          <div class="btns">
            <router-link
              class="btn success"
              v-if="direction.study == 0"
              :to="{
                name: 'reception',
                query: {
                  direction: direction.slogan,
                  faculty: direction.facul?.slogan,
                },
              }"
            >
              {{ $t("admit.title") }}
            </router-link>

            <a
              href="https://admit.umft.uz"
              target="_blank"
              class="btn success"
              v-if="direction.study == 1"
            >
              {{ $t("admit.title") }}
            </a>

            <a href="https://admit.umft.uz" target="_blank" class="btn primary">
              {{ $t("nav.relocation") }}
            </a>

            <router-link
              class="btn"
              :to="{
                name: 'direction',
                params: { slogan: direction.slogan },
              }"
            >
              {{ $t("welcome.more") }}
            </router-link>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    directions: Array,
    title: {
      type: String,
      default: "",
    },
    photo: String,
    color: String,
    top: String,
  },
  computed: {
    ...mapGetters(["locale"]),
  },
};
</script>

<style></style>
