<template>
  <div class="wrapper" ref="wrapper">
    <WelcomeBox />
    <div ref="campus">
      <AboutBox />
    </div>
    
    <ProgramList />
    <MissionBox />
    <div ref="direction" id="direction">
      <PriceList />
    </div>
    <partners-list />
    <!-- <quote-list/> -->
    <!-- <sub-box/> -->
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import ProgramList from "@/components/home/ProgramList.vue";
import PriceList from "@/components/home/PriceList.vue";

export default {
  components: {
    WelcomeBox: defineAsyncComponent(() =>
      import("@/components/home/WelcomeBox.vue")
    ),
    AboutBox: defineAsyncComponent(() =>
      import("@/components/home/AboutBox.vue")
    ),
    ProgramList,
    MissionBox: defineAsyncComponent(() =>
      import("@/components/home/MissionBox.vue")
    ),
    PriceList,
    PartnersList: defineAsyncComponent(() =>
      import("@/components/PartnersList.vue")
    ),
    // QuoteList,
    // SubBox
  },
  methods: {
    scrollHome(to) {
      if (to === "campus") {
        this.$refs.campus.scrollIntoView({ behavior: "smooth" });
      }
      if (to === "direction") {
        this.$refs.direction.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  mounted() {
    this.$store.commit("navclass", "home");
    document.title = "Bosh sahifa";
    if (this.$route.query.to) {
      this.scrollHome(this.$route.query.to);
    }
  },
  watch: {
    $route(to) {
      if (to.query.to) {
        this.scrollHome(to.query.to);
      }
      this.$store.commit("navclass", "home");
    },
  },
};
</script>

<style lang="scss" scoped></style>
