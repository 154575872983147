<template>
  <footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-4 col-md-12">
                <div class="footer__logo">
                    <img src="@/assets/logo.svg" alt=""/>
                    University of Management and Future Technologies
                </div>
                <p>{{ $t('footer.text') }}</p>
                <div class="d-flex">
                    <el-image 
                        class="footer__file"
                        :src="licenseUrl" 
                        :preview-src-list="licenseList">
                    </el-image>
                    <el-image 
                        class="footer__file"
                        :src="guvohnoma"
                        :preview-src-list="guvohnomaList"
                        >
                    </el-image>
                </div>
                <div class="footer__lit">Litsenziya #L-4409567, 09.11.2022y.</div>
            </div>
            <div class="col-8 col-md-12">
                <div class="footer__row d-flex justify-content-between">
                    <div class="footer__box">
                        <div class="footer__title">{{ $t('about.title') }}</div>
                        <ul>
                            <li><router-link to="/about">{{ $t('menu.about_f') }}</router-link></li>
                            <li><router-link to="/?to=direction">{{ $t('menu.direction') }}</router-link></li>
                            <li><router-link to="/structure">{{ $t('menu.structure') }}</router-link></li>
                            <li><router-link to="/condition">{{ $t('menu.condition') }}</router-link></li>
                            <li><router-link to="/admission">{{ $t('menu.admission') }}</router-link></li>
                        </ul>
                    </div>
                    <div class="footer__box">
                        <div class="footer__title">{{ $t('menu.faculty') }}</div>
                        <ul>
                            <li v-for="direction of facultys" :key="direction._id">
                            <router-link :to="`/faculty/${direction.slogan}`">
                            {{locale == 'uz' 
                                ? direction.title 
                                : direction.translate.find(t => t.language == locale) 
                                    ? direction.translate.find(t => t.language == locale).title 
                                    : direction.title}}
                            </router-link>
                        </li>
                        </ul>
                    </div>
                    <div class="footer__box">
                        <div class="footer__title">{{ $t('menu.contact') }}</div>
                        <ul>
                            <li class="d-flex mb-10">
                                <img src="@/assets/icons/location.svg" class="mr-10" alt="">
                                {{ $t('header.address') }}
                            </li>
                            <li class="d-flex mb-10">
                                <img src="@/assets/icons/email.svg" class="mr-10" alt="">
                                info@umft.uz
                            </li>
                            <li class="d-flex mb-10">
                                <img src="@/assets/icons/phone.svg" class="mr-10" alt="">
                                (55) 506 88 88
                            </li>
                            <li class="d-flex mb-10">
                                <img src="@/assets/icons/phone.svg" class="mr-10" alt="">
                                (55) 520 88 88
                            </li>
                        </ul>
                        <div class="footer__social">
                            <a href="https://www.facebook.com/umft.uz" target="_blank">
                                <img src="@/assets/icons/fb.svg" alt="">
                            </a>
                            <a href="https://t.me/umftuz" target="_blank">
                                <img src="@/assets/icons/telegram.svg" alt="">
                            </a>
                            <a href="https://www.instagram.com/umft.uz/" target="_blank">
                                <img src="@/assets/icons/inst.svg" alt="">
                            </a>
                            <a href="https://www.youtube.com/channel/UCodfoirEfSoXlCquATFavaQ" target="_blank">
                                <img src="@/assets/icons/yt.svg" alt="">
                            </a>
                            <a href="https://www.linkedin.com/company/umft-uz/" target="_blank">
                                <img src="@/assets/icons/lin.svg" alt="">
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="footer__copy">
            © University of Management and Future Technologies {{ new Date().getFullYear() }}
        </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters([
            'facultys',
            'locale'
        ]),
    },
    data(){
        return {
            guvohnoma:require('@/assets/guvohnoma.jpg'),
            guvohnomaList:[require('@/assets/guvohnoma.jpg')],
            licenseUrl:require('@/assets/licence1.jpg'),
            licenseList:[
                require('@/assets/licence1.jpg'),
                require('@/assets/licence2.jpg'),
            ]
        }
    }
}
</script>

<style lang="scss">
@import '@/styles/components/footer.scss';
</style>