
const page = {
    state:() => ({
        page:{}
    }),
    getters:{
        page(state){
            return state.page
        }
    },
    mutations:{
        page(state,payload){
            state.page = payload
        }
    },
    actions:{
        async getPage(context,payload){
            let res = await context.dispatch('getAxios',`page/${payload}`)
            // console.log(res.data)
                context.commit('page',res.data)  
             
        },
    }
}

export default page