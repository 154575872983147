import axios from "axios"

const helpers = {
    actions: {
        getAxios(context,payload){
            return axios.get(`${context.getters.url}/${payload}`)
        },
        otherGetAxios(context,payload){
            return axios.get(`${context.getters.uri}/${payload}`)
        },
        postAxios(context,payload){
            return axios.post(`${context.getters.url}/${payload.url}`,payload.data)
        },
        
    }
}

export default helpers