<template>
  <div class="main">
    <HeaderBox :headerclass="headerToggle" />
    <router-view />
    <footer-box />
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import FooterBox from "./components/FooterBox.vue";
import HeaderBox from "./components/HeaderBox.vue";
export default {
  components: {
    HeaderBox,
    FooterBox,
  },
  data() {
    return {
      headerToggle: "",
      refURL: "https://marketingapi.umft.uz/history",
    };
  },
  watch: {
    $route(to) {
      this.$store.commit("navclass", to.name);
      window.scrollTo(0, 0);
      this.$store.commit("headerBtn", { lov: "app", val: true });
    },
  },

  methods: {
    ...mapActions(["allDirection", "allFaculty"]),
    handleScroll() {
      this.headerToggle = window.scrollY > 0 ? "fixed" : "";
    },
    async addHistory() {
      await axios.post(this.refURL, {
        ref: this.$route.query?.ref || "",
      });
    },
  },
  async mounted() {
    setTimeout(async () => {
      if (this.$route.query?.ref) {
        await this.addHistory();
      }
    }, 100);

    window.addEventListener("scroll", this.handleScroll);
    this.$store.commit("navclass", this.$route.name);
    this.allFaculty();
    this.allDirection();
    console.log(this.$route.query?.ref);
  },
};
</script>

<style lang="scss">
@import "@/styles/app.scss";
</style>
