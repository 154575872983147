
const faculty = {
    state: () => ({
        faculty:{},
        facultys:[]
    }),
    getters:{
        faculty(state){
            return state.faculty
        },
        facultys(state){
            return state.facultys
        },
    },
    mutations:{
        faculty(state,payload){
            state.faculty = payload
        },
        facultys(state,payload){
            state.facultys = payload
        }
    },
    actions:{
        allFaculty(context){
            context.dispatch('getAxios','faculty/all')
            .then(res => {     
                console.log(res.data)         
                context.commit('facultys',res.data)  
            })
        },
        async getFaculty(context,payload){
            let res = await context.dispatch('getAxios',`faculty/get/${payload}`)
            if (res.status){
                context.commit('faculty',res.data)
            }
        },
        async returnGetFaculty(context,payload){
            return await context.dispatch('getAxios',`faculty/get/${payload}`)

        }
    }
}

export default faculty