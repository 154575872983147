export const en = {
  header: {
    address: "Tashkent sh. Diydar street. 71",
  },
  menu: {
    about: "About us",
    univer: "University",
    campus: "Our campus",
    structure: "Organizational structure",
    faculty: "Faculties",
    bakalavr: "Bachelor`s degree",
    magistr: "Master`s degree",
    admis: "Admission Commission",
    direction: "Directions",
    condition: "Admission Requirements",
    admission: "Admission quota",
    relocation: "Student transger requirements",
    contract: "TUITION FEES OF ACADEMIC PROGRAMS",
    contact: "Contacts",
    faq: "FAQ",
    about_f: "About us",
    grant: "Grant",
    conf: "Conference",
    journal: "Journal",
    gallery: "Gallery",
    promo: "Admission action",
    vaucher: "Admission voucher",
  },
  double: `DOUBLE DEGREE PROGRAM`,
  double_title: "BETWEEN THE UMFT AND FAR EAST UNIVERSITY",
  grant: {
    discount: "Grant discount",
    direction: "Degree",
    title: "Grant tanlovining final bosqich natijalari",
    50: `50 foizlik grant sohiblari ro'yhati`,
    75: `75 foizlik grant sohiblari ro'yhati`,
    100: `100 foizlik grant sohiblari ro'yhati`,
    25: `Grantsiz 25 foizlik chegirmaga ega bo'lganlar ro'yhati`,
    name: "FISh",
    ball: "Ball",
  },
  certdownload: "Certificate file",
  nav: {
    admit: "Submit an application",
    relocation: "Transfer",
  },
  vaucher: "Voucher number (if available)",
  about: {
    item_1: {
      title: "Education for all",
      text: "Identifying, supporting and providing scholarships to talented and skilled youth",
    },
    item_2: {
      title: "Hybrid education",
      text: "Distance learning in internationally certified training courses in conjunction with the curriculum",
    },
    item_3: {
      title: "Science",
      text: "Ensuring the effective integration of science, education and industry cooperation",
    },
    item_4: {
      title: "International standard",
      text: "Training of highly qualified specialists with modern knowledge based on international standards of education",
    },

    title: "ABOUT US",
    text: `<h2>STUDY BUILDING AND BASIC FACILITIES CREATED FOR THE STUDENTS</h2>
        <p>Teaching at the university is delivered by leading professors and teachers in classrooms equipped with modern technologies</p>
        <p>The university has lecture and practical training rooms, computer laboratories, an information resource center and an electronic library equipped with up-to-date computers.</p>
        <p>The university building is fully covered by Wi-Fi internet network, and students are given the opportunity to use it freely</p>`,
  },
  welcome: {
    title: "Our university",
    slogan: "The best partner for your future",
    text: `Based on international experiences, training of highly qualified specialists in line with the requirements of the labor market based on the advanced standards of higher education`,
    more: "More",
  },
  welcome_2: {
    title: "Diqqat tanlov!!!",
    slogan: "180 ta grant o`rinlari",
    text: `University of Management and Future Technologies universiteti 2024/2025 o'quv yili uchun grant o'rinlariga abiturientlarning qabul tanlovini e'lon qiladi.`,
    more: "Tanlovda qatnashish",
    result: "Natijalar",
  },
  welcome_3: {
    title: "DIQQAT AKSIYA!!!",
    slogan: "Talaba bo‘ling, avtomobil sohibiga aylaning,",
    text: `University of management and future technologies universiteti 2024/2025 oquv yili uchun qabul aksiyasini e'lon qiladi`,
    more: "Aksiya shartlari",
  },
  programlist: {
    title: "University Faculties",
    text: "The university has three faculties, which train bachelor and master specialists in IT technology engineering, economics and business management, pedagogy and philology.",
  },
  mission: {
    title: "Our highest goals and objectives",
    subtitle: "Our goal",
    text: `<p>To be one of the universities with high rating indicators in the field of higher education, providing quality education at the level of international standards, training competitive, modern mature specialists</p>`,
  },
  price: {
    title: "List of academic programs",
    subtitle: `Academic Programs`,
    currency: `million sum/year`,
  },
  partners: {
    tuit: "Tashkent University of Information Technologies",
    pedagogika: "Tashkent State Pedagogical University",
    moliya: "Tashkent Institute of Finance",
    guldu: "Gulistan State University",
    nammti: "Namangan Institute of engineering and technology",
    digital: "DIGITAL ECONOMY RESEARCH CENTER",
    avloniy: "A.Avlony National Research Institute",
    center: "Digital Education Technology Development Center",
    title: "Partners",
  },
  footer: {
    text: "Reach the heights of science with us and become a qualified specialist",
    lic: "",
  },
  modal: {
    title: "Send your question",
    name: "Introduce yourself",
    question: "The text of your question",
    send: "Send",
    thnks:
      "Your question has been sent successfully. <br> Your question will be considered and answered. Thank you for your question.",
  },
  breadcrumb: {
    home: "Home",
  },
  contact: {
    title: "Contacts",
    phone: "Phone",
    address: {
      title: "Address",
      value: "71 Diydar street, Chilonzor district, Tashkent city",
      value_2:
        "Tashkent city, Bog'iboston street, 3A house (Rakat neighborhood, Textile)",
    },
    email: "Email",
    main_place: "Main campus",
    second_place: "Second campus",
    second_text:
      "Arriving at the 2nd building of the university, get off at the <u>Tekstil Kombinati</u> stop of the above buses",
  },
  bus: {
    title: "Bus lines",
    station: "Bus stop name",
    number: "Bus number",
    direction: "Bus line",
    time: "Time",
    line_1: `Bus stop "Algoritm Zavodi"`,
    mini: "Minubus lines",
    mininumber: "Minibus number",
  },
  page: {
    faq: "FAQ",
    question: "Send answer",
  },
  report: {
    title: "Personal Information",
    name: "Fullname.",
    lvl: "Type of study",
    studyType: "Form of education",
    year: "Study period",
    course: "Study course",
    courseCur: "course",
    info: "Contract information",
    direction: "Course of Study",
    quantity: "Contract number",
    date: "Date of contract",
    summa: "Contract amount",
    copy: "A copy of the contract",
    download: "Download",
    sum: "sum",
  },
  admit: {
    dtm: "DTM ID (if available)",
    sale: "Upload discount certificate (if available)",
    studyType_off: "Full-time",
    studyType_on: "Part-time",
    title: "Submit an application",
    success_title: "Dear applicant!!!",
    success_text:
      "Your application and attached documents have been successfully sent to the UMFT Admissions Committee. Soon you will receive an SMS notification that the admissions committee has accepted your documents",
    step_1: "Personal information",
    step_2: "Education information",
    step_3: "Submission",
    lname: "Surname",
    name: "Name",
    secondname: "Middle name",
    gender: "Gender",
    male: "Male",
    female: "Female",
    phone: "Phone",
    email: "Email",
    country: "Citizen",
    selectCountry: "Select from list",
    passport: "Passport / ID card number",
    bdate: "Birthday",
    province: "Region",
    selectProvince: "Select from list",
    district: "City/District",
    selectDistrict: "Select from list",
    address: "Address",
    rectype: "Type of admission",
    selectList: "Select from list",
    studyName: "Previous education",
    studyNameShow: "Previous education",
    studyId: "Diploma/Certificate number",
    studyShow: "Diploma/Certificate number",
    foreign: "Education language",
    studyPart: "Type of education",
    direction: "Academic program",
    directionShow: "Academic program",
    language: "Education language",
    studyType: "Form of education",
    studyDocument: "Upload diploma/certificate files",
    studyDocumentShow: "Diploma/certificate file",
    studyDocumentError: "The size of the file should not exceed 4 MB",
    academ: "Upload Academic reference/Transcript",
    academError: "Academic reference file size should not exceed 4 MB",
    academShow: "Academic reference/Transcript files",
    pasImg: "Upload passport file",
    pasImgError: "The size of the Passport file should not exceed 4 MB",
    pasShow: "Passport files",
    img: "Upload photo file",
    imgShow: "Photo files",
    imgError: "The size of the image file should not exceed 4 MB",
    imgType: "Only img, png format files can be uploaded to the image file",
    aggree: "I confirm the authenticity and correctness of the information",
    prev: "Back",
    next: "Next",
    code: "Code confirmation",
    send: "Send",
    save: "Save",
    info: "code sent to phone",
    ok: "Confirm",
    cur: "",
    exam: "Exam time",
    examtitle: "Entrance exam",
    sert: "Til bilish sertifikatlari (agar mavjud bo`lsa)",
    sertShow: "Til bilish sertifikatlari",
    choose_direction: "Choose direction",
    contract_salary: "Tuition Fee",
    private_info: "Personal information",
    contact_info: "Contact Information",
    study_info: "Educational Information",
    choose_studyType: `In what form do you want to study?`,
    choose_language: `In what language do you want to study?`,
    choose_foreign: `What foreign language have you studied?`,
    choose_exam: `Select entrance exam date`,
  },
};
